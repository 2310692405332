import React from "react";
import ReactDOM from "react-dom";
import "bulma/css/bulma.css";
import HomePage from "./components/home";
import Publications from "./components/publications";
import Research from "./components/research";
import Projects from "./components/projects";
import { BrowserRouter, Route, Switch } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/projects" component={Projects} />
      <Route path="/publications" component={Publications} />
      <Route path="/research" component={Research} />
      <Route path="/" component={HomePage} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
