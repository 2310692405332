import React, { Component } from "react";
import { Link } from "react-router-dom";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.homeclass = this.props.name === "home" ? "has-text-link" : "";
    this.projectClass = this.props.name === "project" ? "has-text-link" : "";
    this.pubclass = this.props.name === "pub" ? "has-text-link" : "";
    this.resclass = this.props.name === "res" ? "has-text-link" : "";
  }

  render() {
    return (
      <div className="column is-2">
        <div className="my-6">
          <span className="is-size-3 has-text-black">Minho Eom</span>
        </div>
        <aside className="menu my-4">
          <ul className="menu-list">
            <li>
              <Link to="/">
                <span className={this.homeclass}>Home</span>
              </Link>
            </li>
            <li>
              <Link to="/projects">
                <span className={this.projectClass}>Projects</span>
              </Link>
            </li>
            <li>
              <Link to="/publications">
                <span className={this.pubclass}>Publications</span>
              </Link>
            </li>
            <li>
              <Link to="/research">
                <span className={this.resclass}>Research</span>
              </Link>
            </li>
          </ul>
        </aside>
        <div className="mr-6 mt-4 mb-5">
          <figure className="image is-4by5">
            <img src="MinhoEom-profile.jpg" alt="Minho Eom Profile" />
          </figure>
        </div>
        <div>
          <ul>
            <li>Graduate Student</li>
            <li>
              <a href="https://ee.kaist.ac.kr/">
                <span className="is-size-7">
                  School of Electrical Engineering
                </span>
              </a>
            </li>
            <li>
              <a href="https://kaist.ac.kr">KAIST</a>
            </li>
          </ul>
        </div>
        <div className="my-4 has-text-weight-semibold">
          <a href="MinhoEom_CV.pdf" target="_blank">
            Curriculum Vitae (PDF)
          </a>
        </div>
        <div className="mr-5 mt-6">
          <a href="https://www.nicalab.com/" target="_blank" rel="noreferrer">
            <figure className="image">
              <img src="NICA_logo.webp" alt="NICA Lab Logo" />
            </figure>
          </a>
          <div className="mt-5">
            <a href="https://ee.kaist.ac.kr/" target="_blank" rel="noreferrer">
              <figure className="image">
                <img src="KAIST_EE.png" alt="KAIST Electrical Engineering" />
              </figure>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
