import React, { Component } from "react";
import Navbar from "./navbar";

class Publications extends Component {
  render() {
    return (
      <div className="container">
        <div className="columns">
          <Navbar name="pub" />
          <div className="column is-7 mx-6">
            <div className="mt-6">Publications</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Publications;
