import React, { Component } from "react";

class Modal extends Component {
  getModalClass() {
    if (this.props.isactive) {
      return "modal is-active";
    } else {
      return "modal";
    }
  }

  render() {
    return (
      <div className={this.getModalClass()}>
        <div
          className="modal-background"
          onClick={this.props.activeHandler}
        ></div>
        <div className="modal-content">
          <div className="box">{this.props.content}</div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={this.props.activeHandler}
        ></button>
      </div>
    );
  }
}

export default Modal;
