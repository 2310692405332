import React, { Component } from "react";
import Navbar from "./navbar";
import Modal from "./Modal";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

class Projects extends Component {
  state = { isactive: false, isEnter: false };

  render() {
    return (
      <div className="container">
        <div className="columns">
          <Navbar name="project" />
          <div className="column is-7 mx-6 mt-6">
            <div className="has-text-centered my-6">
              <span>Project Lists</span>
            </div>
            <Modal
              isactive={this.state.isactive}
              activeHandler={() => this.setState({ isactive: false })}
              content={
                <div>
                  <div className="has-text-centered mb-3">
                    <span className="has-text-weight-semibold">
                      KAIST CS473 Project: Hang On
                    </span>
                  </div>
                  <CarouselProvider
                    naturalSlideWidth={100}
                    naturalSlideHeight={50}
                    totalSlides={4}
                  >
                    <Slider>
                      <Slide index={0}>
                        <img src="figure/homePage.PNG" alt="Home page" />
                      </Slide>
                      <Slide index={1}>
                        <img src="figure/room.PNG" alt="Main room" />
                      </Slide>
                      <Slide index={2}>
                        <img src="figure/clink.PNG" alt="Clinking feature" />
                      </Slide>
                      <Slide index={3}>
                        <img src="figure/focus.PNG" alt="Focusing feature" />
                      </Slide>
                    </Slider>
                    <div className="has-text-centered">
                      <ButtonBack className="button is-white">
                        <span className="icon is-small">
                          <i className="fas fa-chevron-left"></i>
                        </span>
                      </ButtonBack>
                      <ButtonNext className="button is-white">
                        <span className="icon is-small">
                          <i className="fas fa-chevron-right"></i>
                        </span>
                      </ButtonNext>
                    </div>
                  </CarouselProvider>
                  <div>
                    <div>
                      <a
                        href="https://hangon.minhoeom.com"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <span className="icon">
                          <i className="fas fa-home"></i>
                        </span>
                      </a>
                      <a
                        href="https://github.com/EOMMINHO/HangOnClient"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <span className="icon">
                          <i className="fab fa-github"></i>
                        </span>
                      </a>
                    </div>
                    <span>
                      This is a KAIST CS473 project aims to solve the following
                      problem statement. Drinking online via the current video
                      chat system fails to give the liveness of offline parties
                      and has difficulties in providing dynamic interactions
                      between the group members.
                    </span>
                  </div>
                </div>
              }
            />
            <div
              className={
                this.state.isEnter
                  ? "box has-text-centered py-5 has-background-grey-lighter"
                  : "box has-text-centered py-5 has-background-white-ter"
              }
              onClick={() => this.setState({ isactive: true })}
              style={{ cursor: "pointer" }}
              onMouseEnter={() => this.setState({ isEnter: true })}
              onMouseLeave={() => this.setState({ isEnter: false })}
            >
              <div>
                <span>KAIST CS473 Project</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Projects;
