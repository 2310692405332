import React, { Component } from "react";
import Navbar from "./navbar";

class HomePage extends Component {
  render() {
    return (
      <div className="container">
        <div className="columns">
          <Navbar name="home" />
          <div className="column is-7 mx-6">
            <div className="mt-6 ">
              Minho Eom is a Graduate Student in the{" "}
              <a href="https://ee.kaist.ac.kr/">
                School of Electrical Engineering
              </a>{" "}
              at <a href="https://www.kaist.ac.kr/">KAIST</a>, and a researcher
              in <a href="https://www.nicalab.com/">NICALab</a> (the
              Neuro-Instrumentation & Computational Analysis Lab). His research
              in computational neuroscience focuses on trying to{" "}
              <span className="has-text-weight-medium">
                discover the mechanism of a brain function
              </span>
              : analysis of neuronal data, processing of neuronal images, and
              implementing neuro-instruments.
            </div>
            <div className="mt-4">
              He earned his B.S. from{" "}
              <a href="https://www.kaist.ac.kr/">KAIST</a>, 2021.
            </div>
            <div className="columns mt-6">
              <div className="column">
                <div>
                  <span className="has-text-black has-text-weight-semibold">
                    Contact Info
                  </span>
                  <ul className="is-size-7">
                    <li>
                      <a href="mailto:djaalsgh159@kaist.ac.kr">
                        djaalsgh159@kaist.ac.kr
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/in/minho-eom/">
                        LinkedIn
                      </a>
                    </li>
                    <li>
                      <a href="https://github.com/EOMMINHO">GitHub</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="column">
                <div>
                  <span className="has-text-black has-text-weight-semibold">
                    Want to meet?
                  </span>
                  <ul className="is-size-7">
                    <li>Please contact before coming</li>
                  </ul>
                  <ul className="is-size-7 mt-4">
                    <li>N24 #4103, KAIST</li>
                    <li>291 Daehak-ro, Yuseong-gu</li>
                    <li>Daejeon, 34141, Republic of Korea</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <span className="is-size-5 has-text-black has-text-weight-semibold">
                Teaching
              </span>
              <ul className="is-size-7">
                <li>
                  Spring 2021 [CoE202] Basics of Artificial Intelligence
                  (Teaching Assistant)
                </li>
              </ul>
            </div>
            <div className="mt-6">
              <span className="is-size-5 has-text-black has-text-weight-semibold">
                Previous Affiliations
              </span>
              <ul className="is-size-6">
                <li>
                  B.S. in{" "}
                  <a href="https://cs.kaist.ac.kr/">School of Computing</a> and{" "}
                  <a href="https://ee.kaist.ac.kr/">Electrical Engineering</a>{" "}
                  from <a href="https://www.kaist.ac.kr/kr/">KAIST</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;
